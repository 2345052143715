import { useMutation } from '@tanstack/react-query';

import type { SwitchAccountPayload } from '@/api/v4/auth.api';
import { switchAccount } from '@/api/v4/auth.api';
import { queryClient } from '@/config/query-client';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { notify } from '@/ui/snackbar/notify';
import { getSessionToken } from '@descope/react-sdk';
import { useTranslation } from 'react-i18next';

export const useSwitchAccount = () => {
  const { t } = useTranslation('default');

  const navigateTo = useNavigateTo();
  const descopeToken = getSessionToken();

  return useMutation(
    async (userInAccountId: number) => {
      await queryClient.cancelQueries();
      navigateTo.home();
      const payload: SwitchAccountPayload = {
        userInAccountId,
        descopeToken,
      };
      return await switchAccount(payload);
    },
    {
      onSuccess: async () => {
        await queryClient.resetQueries();
      },
      onError: () => {
        notify({
          message: t`unknownError`,
        });
      },
    },
  );
};
