import { useRecoilValue } from 'recoil';

import { SuspenseFallback } from './components/suspense-fallback/suspense-fallback';
import { AddExampleCompaniesToExploreFormModal } from './features/explore/form/add-example-companies-to-explore-form-modal';
import { AddCompanyModal } from './features/modals/add-company-modal/add-company-modal';
import { AddCompanyToProjectsModal } from './features/modals/add-company-to-projects-modal/add-company-to-projects-modal';
import { AddTeamMemberModal } from './features/modals/add-team-member-modal/add-team-member-modal';
import { AskXModal } from './features/modals/ask-x-modal';
import { ChangePasswordModal } from './features/modals/change-password-modal';
import { ChangeUserRoleModal } from './features/modals/change-user-role-modal';
import { ConfirmDeleteModal } from './features/modals/confirm-delete-modal';
import { ConfirmDiscardChangesModal } from './features/modals/confirm-discard-explore-form-changes-modal';
import { ConfirmDiscardMeetingFormChangesModal } from './features/modals/confirm-discard-meeting-form-changes-modal';
import { CreateNewCompanyModal } from './features/modals/create-new-company-modal/create-new-company-modal';
import { DealModal } from './features/modals/deal-modal';
import { DeleteCompaniesModal } from './features/modals/delete-companies-modal/delete-companies-modal';
import { DeleteCompanyFileModal } from './features/modals/delete-company-file-modal/delete-company-file-modal';
import { DeleteCompanyModal } from './features/modals/delete-company-modal';
import { DeleteProjectFileModal } from './features/modals/delete-project-file-modal/delete-project-file-modal';
import { DeleteProjectListingModal } from './features/modals/delete-project-listing-modal/delete-project-listing-modal';
import { DeleteProjectModal } from './features/modals/delete-project-modal/delete-project-modal';
import { DeleteTeamFileModal } from './features/modals/delete-team-file-modal/delete-team-file-modal';
import { DeleteTeamMemberModal } from './features/modals/delete-team-member-modal';
import { DeleteTeamModal } from './features/modals/delete-team-modal/delete-team-modal';
import { DuplicateProjectModal } from './features/modals/duplicate-project-modal';
import { EnhanceProjectIntroModal } from './features/modals/enhance-project-modals/intro/enhance-project-intro-modal';
import { ProjectListingSuggestionFormModal } from './features/modals/enhance-project-modals/project-listing-suggestion-form-modal';
import { InviteUserModal } from './features/modals/invite-modal/invite-user-modal';
import { ManageTagsModal } from './features/modals/manage-tags-modal/manage-tags-modal';
import { RevokeInvitationModal } from './features/modals/revoke-invitation-modal';
import { SearchModal } from './features/modals/search-modal';
import { Set2FAMethodModal } from './features/modals/set-2fa-method-modal';
import { ShareCompanyModal } from './features/modals/share-modal/share-company/share-company-modal';
import { ShareProjectModal } from './features/modals/share-modal/share-project/share-project-modal';
import { SharedAssetsModal } from './features/modals/shared-assets-modal';
import { VerifyCompanyModal } from './features/modals/verify-company-modal';
import { modalState } from './state/modal.state';
import { LoadCompaniesToProjectModal } from './features/modals/load-companies-to-project-modal';
import { CopyProjectModal } from './features/modals/copy-project';

export const Modals = () => {
  const modal = useRecoilValue(modalState);

  return (
    <SuspenseFallback>
      {modal?.state === 'set2FAMethod' && <Set2FAMethodModal />}
      {modal?.state === 'copyProjectModal' && <CopyProjectModal />}
      {modal?.state === 'changePassword' && <ChangePasswordModal />}
      {modal?.state === 'deleteProjectListing' && <DeleteProjectListingModal />}
      {modal?.state === 'deleteProject' && <DeleteProjectModal />}
      {modal?.state === 'loadCompaniesToProject' && (
        <LoadCompaniesToProjectModal />
      )}
      {modal?.state === 'deal' && <DealModal />}
      {modal?.state === 'manageTags' && <ManageTagsModal />}
      {modal?.state === 'duplicateProject' && <DuplicateProjectModal />}
      {modal?.state === 'shareProject' && <ShareProjectModal />}
      {modal?.state === 'addCompany' && <AddCompanyModal />}
      {modal?.state === 'addCompanyToProjects' && (
        <AddCompanyToProjectsModal companyName={modal?.companyName} />
      )}
      {modal?.state === 'createNewCompany' && (
        <CreateNewCompanyModal
          closeOnCreate={modal?.closeOnCreate}
          defaultSearchPhrase={modal?.defaultSearchPhrase}
          defaultWebsite={modal?.defaultWebsite}
          navigateToPageOnCreated={modal?.navigateToPageOnCreated}
          onClose={modal?.onClose}
        />
      )}
      {modal?.state === 'shareCompany' && <ShareCompanyModal />}

      {modal?.state === 'deleteTeam' && <DeleteTeamModal />}
      {modal?.state === 'deleteTeamMember' && <DeleteTeamMemberModal />}

      {modal?.state === 'deleteCompany' && <DeleteCompanyModal />}
      {modal?.state === 'deleteCompanies' && <DeleteCompaniesModal />}

      {modal?.state === 'deleteTeamFile' && <DeleteTeamFileModal />}
      {modal?.state === 'deleteCompanyFile' && <DeleteCompanyFileModal />}
      {modal?.state === 'deleteProjectFile' && <DeleteProjectFileModal />}

      {modal?.state === 'inviteUser' && <InviteUserModal />}
      {modal?.state === 'revokeInvitation' && <RevokeInvitationModal />}

      {modal?.state === 'addTeamMember' && <AddTeamMemberModal />}
      {modal?.state === 'verifyCompany' && <VerifyCompanyModal />}
      {modal?.state === 'enhanceProjectIntro' && <EnhanceProjectIntroModal />}
      {modal?.state === 'enhanceProjectForm' && (
        <ProjectListingSuggestionFormModal />
      )}
      {modal?.state === 'discardMeetingFormChanges' && (
        <ConfirmDiscardMeetingFormChangesModal />
      )}
      {modal?.state === 'changeUserRole' && <ChangeUserRoleModal />}
      {modal?.state === 'showSharedAssets' && <SharedAssetsModal />}
      {modal?.state === 'search' && <SearchModal />}
      {modal?.state === 'addExampleCompaniesToExploreForm' && (
        <AddExampleCompaniesToExploreFormModal />
      )}
      {modal?.state === 'confirmDiscardChanges' && (
        <ConfirmDiscardChangesModal />
      )}
      {modal?.state === 'askXModal' && <AskXModal />}

      {/* basic confirm delete modal with default title and description */}
      {modal?.state === 'confirmDelete' && (
        <ConfirmDeleteModal onConfirm={modal.onConfirm} />
      )}
    </SuspenseFallback>
  );
};
