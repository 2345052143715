import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import { companySuggestionsTableHeaders } from './headers-config';
import type { CompanySearchSuggestion } from './company-suggestions-table';
import { useMemo } from 'react';
import { CompanyRowMainCell } from './company-row-main-cell';
import { TagsCell } from '../enriched-table/tags-cell';
import { RelatedProjectsCount } from '@/features/companies/related-projects/related-projects-count';
import { getAbbreviateNumber } from '@/helpers/format-number';
import { formatDate } from '@/helpers/format-date';
import { colors } from '@/theme/colors';
import { TextCell } from '../enriched-table/text-cell';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { CompanyActions } from './company-actions';
import { paths } from '@/routes/helpers/paths';
import { useSetRecoilState } from 'recoil';
import { modalState } from '@/state/modal.state';
import { CellLink } from '../enriched-table/ui';

export const useCompanySuggestionsTableConfig = (
  data: CompanySearchSuggestion[],
  isEnrichDataLoading: boolean,
) => {
  const setModal = useSetRecoilState(modalState);
  const headers = useTranslateHeaders(
    companySuggestionsTableHeaders,
    'default',
    'globalSearch.suggestionsTable',
  );

  const rows = useMemo(() => {
    return data.map(({ basicData, enrichedData }) => {
      return {
        id: basicData.id,
        rowHover: true,
        rowBackground: colors.basics.white,
        rowBackgroundHover: colors.gray.c1,
        onClick: () => setModal(null),
        cells: [
          {
            value: (
              <CellLink to={paths.company({ companyId: basicData.id })}>
                <CompanyRowMainCell
                  name={basicData.name}
                  location={enrichedData.location}
                  logoUrl={basicData.logoUrl}
                  keyOffering={enrichedData.keyOffering}
                  website={enrichedData.website}
                  linkedInUrl={enrichedData.linkedInUrl}
                  isEnrichDataLoading={isEnrichDataLoading}
                />
              </CellLink>
            ),
          },
          {
            value: (
              <CellLink to={paths.company({ companyId: basicData.id })}>
                <TagsCell
                  tags={enrichedData.tags}
                  isLoading={isEnrichDataLoading}
                />
              </CellLink>
            ),
          },
          {
            value: isEnrichDataLoading ? (
              <SkeletonText />
            ) : (
              <CellLink to={paths.company({ companyId: basicData.id })}>
                <RelatedProjectsCount
                  value={enrichedData.projectListingsCount ?? 0}
                  companyId={basicData.id}
                />
              </CellLink>
            ),
          },
          {
            value: (
              <CellLink to={paths.company({ companyId: basicData.id })}>
                <TextCell
                  value={enrichedData.yearEstablished}
                  isLoading={isEnrichDataLoading}
                />
              </CellLink>
            ),
          },
          {
            value: (
              <CellLink to={paths.company({ companyId: basicData.id })}>
                <TextCell
                  value={enrichedData.fundingStage}
                  isLoading={isEnrichDataLoading}
                />
              </CellLink>
            ),
          },
          {
            value: (
              <CellLink to={paths.company({ companyId: basicData.id })}>
                <TextCell
                  value={
                    enrichedData.capitalRaised
                      ? `$${getAbbreviateNumber(enrichedData.capitalRaised)}`
                      : ''
                  }
                  isLoading={isEnrichDataLoading}
                />
              </CellLink>
            ),
          },
          {
            value: (
              <CellLink to={paths.company({ companyId: basicData.id })}>
                <TextCell
                  value={
                    enrichedData.lastEditedDate
                      ? formatDate(enrichedData.lastEditedDate, {
                          format: 'dd MMM, yyyy',
                        })
                      : ''
                  }
                  isLoading={isEnrichDataLoading}
                />
              </CellLink>
            ),
          },
          {
            value: (
              <CompanyActions
                companyId={basicData.id}
                companyName={basicData.name}
              />
            ),
            hoverOnly: true,
          },
        ],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isEnrichDataLoading]);

  return {
    headers,
    rows,
  };
};
