import { modalState } from '@/state/modal.state';
import { IconButton } from '@/ui/button/icon-button';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

export const ProjectActions = ({ projectId }: { projectId: string }) => {
  const { t } = useTranslation('projects');
  const setModalState = useSetRecoilState(modalState);
  const { hasRequiredPermission } = useUserPermissions();
  const hasEditPermission = hasRequiredPermission(
    PERMISSION.ADD_COMPANIES_TO_PROJECTS,
  );

  if (!hasEditPermission) {
    return null;
  }

  return (
    <Tooltip content={t`addCompany.label`}>
      <IconButton
        variant="primary"
        icon="Plus"
        onClick={e => {
          e.stopPropagation();
          setModalState({
            state: 'addCompany',
            projectId,
          });
        }}
      />
    </Tooltip>
  );
};
