import { fonts } from '@/theme/typography';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { RouteLink } from '@/ui/link/route-link';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';
import { styled } from 'goober';

export const TextValue = styled(SmallText)`
  ${({ theme }) => theme.typography.enhance.tableSmallText};
  font-family: ${fonts.wallop};
`;

export const LightTooltip = styled(Tooltip)`
  font-family: ${fonts.wallop};
  background-color: ${({ theme }) => theme.colors.basics.white};
  color: ${({ theme }) => theme.colors.basics.black};
  text-align: left;

  .tippy-arrow {
    color: ${({ theme }) => theme.colors.basics.white};
  }
`;

export const TooltipTitle = styled('h6')`
  font-family: ${fonts.wallop};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.03em;
`;

export const SuggestionWrapper = styled(Flexbox)`
  position: relative;
  min-width: 320px;
  padding: 12px 20px;
`;

export const CellLink = styled(RouteLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 76px;

  &:hover {
    text-decoration: none;
  }
`;
