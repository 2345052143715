import { useRecoilState, useResetRecoilState } from 'recoil';

import type { Sidebar } from '../state/sidebar.state';
import { sidebarState } from '../state/sidebar.state';
import { useActionPanelSearchParams } from '@/features/action-panel/use-action-panel-search-params';

export const useSidebars = () => {
  const [sidebar, setCurrentSidebar] = useRecoilState(sidebarState);
  const resetSidebar = useResetRecoilState(sidebarState);
  const { resetActionPanelSearchParams } = useActionPanelSearchParams();

  const isActionPanelOpen = sidebar === 'ActionsPanel';

  const toggleSelectedSidebar = (selectedSidebar: Sidebar) => {
    if (sidebar === selectedSidebar) {
      return setCurrentSidebar(null);
    }
    setCurrentSidebar(selectedSidebar);
  };

  const toggleNavbar = () => toggleSelectedSidebar('Navbar');
  const toggleSectionsPane = () => toggleSelectedSidebar('SectionsPane');
  const toggleActionPanel = () => {
    toggleSelectedSidebar('ActionsPanel');
    if (isActionPanelOpen) {
      resetActionPanelSearchParams();
    }
  };
  const toggleProjectSidebar = () => toggleSelectedSidebar('ProjectSidebar');

  return {
    resetSidebar,
    toggleNavbar,
    toggleSectionsPane,
    toggleActionPanel,
    toggleProjectSidebar,
    setCurrentSidebar,
    isNavbarOpen: sidebar === 'Navbar',
    isSectionsPaneOpen: sidebar === 'SectionsPane',
    isProjectSidebarOpen: sidebar === 'ProjectSidebar',
    isActionPanelOpen,
  };
};
