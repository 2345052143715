import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import { projectSuggestionsTableHeaders } from './headers-config';
import { useMemo } from 'react';
import { TagsCell } from '../enriched-table/tags-cell';
import { colors } from '@/theme/colors';
import { paths } from '@/routes/helpers/paths';
import { useSetRecoilState } from 'recoil';
import { modalState } from '@/state/modal.state';
import type { ProjectSearchSuggestion } from './project-suggestions-table';
import { ProjectRowMainCell } from './project-row-main-cell';
import { CellLink, TextValue } from '../enriched-table/ui';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { StatusTag } from '@/ui/tag/status-tag';
import { StatusIndicator } from './status-indicator';
import { formatDate } from '@/helpers/format-date';
import { TextCell } from '../enriched-table/text-cell';
import { ProjectActions } from './project-actions';

export const useProjectSuggestionsTableConfig = (
  data: ProjectSearchSuggestion[],
  isEnrichDataLoading: boolean,
) => {
  const setModal = useSetRecoilState(modalState);
  const headers = useTranslateHeaders(
    projectSuggestionsTableHeaders,
    'default',
    'globalSearch.suggestionsTable',
  );

  const rows = useMemo(() => {
    return data.map(({ basicData, enrichedData }) => {
      return {
        id: basicData.id,
        rowHover: true,
        rowBackground: colors.basics.white,
        rowBackgroundHover: colors.gray.c1,
        onClick: () => setModal(null),
        cells: [
          {
            value: (
              <CellLink to={paths.project({ projectId: basicData.id })}>
                <ProjectRowMainCell
                  name={basicData.name}
                  listingsCount={basicData.listingsCount}
                  problemStatement={enrichedData.problemStatement}
                  isEnrichDataLoading={isEnrichDataLoading}
                />
              </CellLink>
            ),
            padding: '0 12px',
          },
          {
            value: (
              <CellLink to={paths.project({ projectId: basicData.id })}>
                <TagsCell
                  tags={enrichedData.tags}
                  isLoading={isEnrichDataLoading}
                />
              </CellLink>
            ),
            padding: '0 12px',
          },
          {
            value: isEnrichDataLoading ? (
              <SkeletonText />
            ) : (
              <CellLink to={paths.project({ projectId: basicData.id })}>
                {enrichedData.mostAdvancedListingStatus ? (
                  <StatusTag variant="gray">
                    {enrichedData.mostAdvancedListingStatus}
                  </StatusTag>
                ) : (
                  <TextValue>-</TextValue>
                )}
              </CellLink>
            ),
            padding: '0 12px',
          },
          {
            value: (
              <CellLink to={paths.project({ projectId: basicData.id })}>
                <TextCell
                  value={enrichedData.createdForTeam?.name ?? '--'}
                  isLoading={isEnrichDataLoading}
                  maxWidth="140px"
                />
              </CellLink>
            ),
            padding: '0 12px',
          },
          {
            value: isEnrichDataLoading ? (
              <SkeletonText />
            ) : (
              <CellLink to={paths.project({ projectId: basicData.id })}>
                {enrichedData.status ? (
                  <StatusIndicator status={enrichedData.status} />
                ) : null}
              </CellLink>
            ),
            padding: '0 12px',
          },
          {
            value: (
              <CellLink to={paths.project({ projectId: basicData.id })}>
                <TextCell
                  value={
                    enrichedData.lastEditedDate
                      ? formatDate(enrichedData.lastEditedDate, {
                          format: 'dd MMM, yyyy',
                        })
                      : ''
                  }
                  isLoading={isEnrichDataLoading}
                />
              </CellLink>
            ),
            padding: '0 12px',
          },
          {
            value: (
              <CellLink to={paths.project({ projectId: basicData.id })}>
                <TextCell
                  value={`${enrichedData.creator.firstName} ${enrichedData.creator.lastName}`}
                  isLoading={isEnrichDataLoading}
                />
              </CellLink>
            ),
            padding: '0 12px',
          },
          {
            value: <ProjectActions projectId={basicData.id} />,
            hoverOnly: true,
          },
        ],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isEnrichDataLoading]);

  return {
    headers,
    rows,
  };
};
