import type { SearchSuggestion } from '@/api/v4/search.api';
import { useEnrichedProjectSuggestions } from './use-enriched-project-suggestions.query';
import type { EnrichedProjectSearchSuggestion } from '@/api/v4/project-enrich.api';
import { useMemo } from 'react';
import { combineProjectData } from './combine-project-data';
import { useProjectSuggestionsTableConfig } from './use-project-suggestions-table-config';
import { TableHeaderCell } from '@/ui/table/infinite-table/table-header-cell';
import { TableBody } from '@/ui/table/infinite-table/table-body';
import { colors } from '@/theme/colors';
import { styled } from 'goober';

export type ProjectSearchSuggestion = {
  basicData: SearchSuggestion;
  enrichedData: EnrichedProjectSearchSuggestion;
};

export const ProjectSuggestionsTable = ({
  data,
}: {
  data: SearchSuggestion[];
}) => {
  const searchSuggestionsProjectIds = data.map(suggestion => suggestion.id);
  const { data: enrichedProjectSuggestions, isLoading } =
    useEnrichedProjectSuggestions(searchSuggestionsProjectIds);

  const combinedProjectsData = useMemo(
    () => combineProjectData(data, enrichedProjectSuggestions),
    [data, enrichedProjectSuggestions],
  );

  const { rows, headers } = useProjectSuggestionsTableConfig(
    combinedProjectsData,
    isLoading,
  );

  if (!isLoading && !enrichedProjectSuggestions) {
    return null;
  }

  return (
    <div>
      <Table>
        <thead>
          <tr>
            {headers.cells.map(cell => (
              <TableHeaderCell key={cell.columnKey} cell={cell} />
            ))}
          </tr>
        </thead>
        <TableBody
          id="project-enriched-suggestions-table-body"
          rows={rows}
          isLoading={isLoading}
          borderColor={colors.gray.c3}
        />
      </Table>
    </div>
  );
};

const Table = styled('table')`
  ${({ theme }) => theme.mixins.scrollbar};
  border-collapse: separate;
  overflow: auto;
  display: inherit;
  max-height: 500px;
  border-radius: 12px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  min-width: 100%;
`;
