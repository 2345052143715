import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export type Sidebar =
  | 'Navbar'
  | 'SectionsPane'
  | 'ActionsPanel'
  | 'ProjectSidebar';

export const sidebarState = atom<Sidebar | null>({
  default: null,
  key: 'sidebarState',
  effects: [persistAtom],
});
