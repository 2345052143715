import { useLogout } from '@/auth/hooks/use-logout.mutation';
import { classList } from '@/helpers/class-list';
import { useSidebars } from '@/hooks/use-sidebars';

import { useSwitchAccount } from '@/auth/hooks/use-switch-account.mutation';
import { DropdownAccounts } from './dropdowns/dropdown-accounts/dropdown-accounts';
import { DropdownSettings } from './dropdowns/dropdown-settings';
import { TooltipDropdownCreate } from './dropdowns/tooltip-dropdown-create';
import { Logo } from './logo';
import {
  NavContainer,
  NavMenuContainer,
  NavSeparator,
  NavToggle,
} from './navbar.styles';
import { NavMenu } from './navmenu';

export const Navbar = () => {
  const logout = useLogout();
  const { isNavbarOpen, toggleNavbar } = useSidebars();
  const { mutateAsync: switchAccount } = useSwitchAccount();

  const onAccountSwitchHandler = async (userInAccountId: number) => {
    await switchAccount(userInAccountId);
  };

  return (
    <NavContainer
      name="app-sidebar"
      direction="column"
      justify="space-between"
      padding="19px 14px 30px 14px"
      className={classList(isNavbarOpen && 'is-open')}
    >
      <div>
        <Logo />
        <NavMenu />
      </div>
      <div>
        <NavMenuContainer>
          <TooltipDropdownCreate />
          <DropdownSettings onLogout={logout} />
          <DropdownAccounts onAccountSwitch={onAccountSwitchHandler} />
        </NavMenuContainer>
        <NavSeparator />
        <NavToggle icon="NaviChevron" onClick={toggleNavbar} />
      </div>
    </NavContainer>
  );
};
