import { Alert } from '@/components/alert/alert';
import { List } from '@/components/list/list';
import { Loader } from '@/components/loader/loader';
import { AccountSettingsIcon } from '@/features/navbar/navbar.styles';
import { getInitials } from '@/helpers/get-initials';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Stack } from '@/ui/line/line';
import { notify } from '@/ui/snackbar/notify';
import { SmallText } from '@/ui/typography/widgets';
import { useCurrentUser } from '@/user/use-current-user.query';
import { styled } from 'goober';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../ui/tooltip/tooltip';
import { useCopyProjectsToAccount } from './use-copy-projects-to-account.mutation';

const LIST_MAX_HEIGHT = '320px';

type Account = {
  id: string;
  name: string;
};

export const CopyToAccount = ({
  projectId,
  onClose,
}: {
  projectId: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation('projects', { keyPrefix: 'copyToAccount' });
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
  const { data: userData, isLoading } = useCurrentUser();
  const {
    mutate: doCopyProject,
    isLoading: isCopying,
    data: copyProjectResponse,
  } = useCopyProjectsToAccount();
  const userAccounts = userData?.userAccounts ?? [];

  const userAccountsList = useMemo(() => {
    return userAccounts
      .filter(v => userData?.account.id !== v.account.id) // Exclude current account
      .map(v => v.account);
  }, [userAccounts]);

  const onCopyHandler = async () => {
    if (selectedAccounts.length === 0) {
      return;
    }

    const accountIds = selectedAccounts.map(v => v.id);
    await doCopyProject(
      { projectIds: [projectId], accountId: accountIds[0] },
      {
        onSuccess: copyProjectResponse => {
          if (copyProjectResponse && copyProjectResponse.errors.length === 0) {
            notify({ message: t`copySuccess` });
            onClose();
          }
        },
      },
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  const isCopyingSuccessful =
    copyProjectResponse && copyProjectResponse.errors.length < 1;

  return (
    <Stack margin="20px 0px">
      {!isCopying && !isCopyingSuccessful && copyProjectResponse && (
        <Alert type="error" messages={copyProjectResponse.errors} />
      )}
      <ListTitle>{t`availableAccounts`}</ListTitle>
      <List<Account>
        maxHeight={LIST_MAX_HEIGHT}
        bordered
        items={userAccountsList}
        renderItem={v => (
          <AccountListItem key={`available-account-${v.id}`} name={v.name} />
        )}
        onSelectItem={setSelectedAccounts}
      />
      <ActionFooter
        onClose={onClose}
        isLoading={isCopying}
        isPrimaryActionDisabled={selectedAccounts.length > 0}
        onCopyHandler={onCopyHandler}
      />
    </Stack>
  );
};

const ActionFooter = ({
  onClose,
  isPrimaryActionDisabled,
  isLoading,
  onCopyHandler,
}: {
  onClose?: () => void;
  isPrimaryActionDisabled?: boolean;
  isLoading?: boolean;
  onCopyHandler: () => void;
}) => {
  const { t } = useTranslation('projects', { keyPrefix: 'copyToAccount' });

  return (
    <Flexbox
      name="available-account-footer"
      justify="space-between"
      padding="32px 0px 0px 0px"
      fullWidth
    >
      <Flexbox name="available-account-warning" gap="4px" alignItems="center">
        <Icon color={colors.gray.c8} icon="Alert" />
        <Tooltip content={t`subWarning`} maxWidth="700px">
          <SmallText>{t`warning`}</SmallText>
        </Tooltip>
      </Flexbox>

      <Flexbox name="available-account-button" gap="24px" alignItems="center">
        <Button
          variant="underlined"
          color={colors.basics.black}
          onClick={onClose}
        >{t`cancel`}</Button>
        <Button
          startIcon="Send"
          endIcon={isLoading ? 'Loader' : undefined}
          disabled={!isPrimaryActionDisabled}
          isLoading={isLoading}
          onClick={onCopyHandler}
        >{t`copyAction`}</Button>
      </Flexbox>
    </Flexbox>
  );
};

const AccountListItem = ({ name }: { name: string; projectCount?: number }) => {
  return (
    <Flexbox name={`available-account-${name}`} alignItems="center" gap="8px">
      <AccountSettingsIcon
        label={getInitials(name)}
        selected={true}
        isButtonHovered={false}
      />
      <AccountName>{name}</AccountName>
    </Flexbox>
  );
};

const ListTitle = styled('h4')`
  ${({ theme }) => theme.typography.copyProjectToAccount.listTitle};
  padding: 0px 7px;
`;

const AccountName = styled(SmallText)`
  color: ${({ theme }) => theme.colors.basics.black};
`;
