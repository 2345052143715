import type { RawDraftContentState } from 'draft-js';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';

const stringToDraftJSContentState = (value: string) => {
  const blocksFromHTML = convertFromHTML(value);
  return ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
};

export const stringToDraftJSContentStateString = (value: string) => {
  const raw: RawDraftContentState = convertToRaw(
    stringToDraftJSContentState(value),
  );

  return JSON.stringify(raw);
};
