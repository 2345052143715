import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import { styled } from 'goober';

import { TOPBAR_HEIGHT } from '@/features/topbar/topbar';
import { Z_INDEX_SLIDING_SIDEBAR } from '@/theme/z-index';

interface DragDropProps {
  children?: ReactNode;
  transition?: boolean;
}

export const SlidingSidebar = ({
  children,
  transition = false,
}: DragDropProps) => {
  return (
    <SlidingSidebarWrapper transition={transition}>
      {children}
    </SlidingSidebarWrapper>
  );
};

export const SLIDING_SIDEBAR_WIDTH = '548px';

const SlidingSidebarWrapper = styled('div', forwardRef)<{
  transition: boolean;
}>`
  height: 100%;
  width: ${SLIDING_SIDEBAR_WIDTH};
  position: fixed;
  right: 0;
  top: calc(${TOPBAR_HEIGHT} - 10px);
  z-index: ${Z_INDEX_SLIDING_SIDEBAR};
  background-color: ${({ theme }) => theme.colors.basics.white};
  ${({ theme, transition }) =>
    transition &&
    theme.animations.slideInHorizontally('right', SLIDING_SIDEBAR_WIDTH)};
`;
