import type { ReactElement } from 'react';
import { cloneElement, useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { Z_INDEX_POPPER_CONTENT } from '@/theme/z-index';
import type { Placement } from '@popperjs/core';

interface PopperProps {
  visibleElement: ReactElement;
  PopperContent: ReactElement;
  placement?: Placement;
  dataId?: string;
  dataName?: string;
}

export const PopperComponent = ({
  visibleElement,
  PopperContent,
  placement = 'bottom',
  dataId,
  dataName,
}: PopperProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const referenceElement = useRef<HTMLDivElement | null>(null);
  const popperElement = useRef<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement,
    },
  );

  const handleMouseEnter = useCallback(() => setIsVisible(true), []);
  const handleMouseLeave = useCallback(() => setIsVisible(false), []);

  return (
    <div>
      <div
        ref={referenceElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-id={dataId}
        data-name={dataName}
      >
        {visibleElement}
      </div>

      <div
        ref={popperElement}
        style={{
          ...styles.popper,
          visibility: isVisible ? 'visible' : 'hidden',
          zIndex: Z_INDEX_POPPER_CONTENT,
        }}
        {...attributes.popper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {cloneElement(PopperContent, {
          isVisible,
        })}
      </div>
    </div>
  );
};
