import { useTranslation } from 'react-i18next';
import type { ProjectSearchSuggestion } from './project-suggestions-table';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { styled } from 'goober';
import { Stack } from '@/ui/line/line';
import { colors } from '@/theme/colors';
import { LightTooltip, TextValue, TooltipTitle } from '../enriched-table/ui';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { Icon } from '@/ui/icons/icon';

type ProjectRowMainCellProps = Pick<
  ProjectSearchSuggestion['enrichedData'],
  'problemStatement'
> &
  Pick<ProjectSearchSuggestion['basicData'], 'name' | 'listingsCount'> & {
    isEnrichDataLoading: boolean;
  };

export const ProjectRowMainCell = ({
  name,
  problemStatement,
  listingsCount,
  isEnrichDataLoading,
}: ProjectRowMainCellProps) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'globalSearch.suggestionsTable',
  });

  return (
    <SuggestionWrapper
      name="project-suggestion-main-cell"
      justify="space-between"
      alignContent="center"
      alignItems="center"
    >
      <Flexbox
        name="project-suggestion-data"
        justify="space-between"
        fullWidth
        alignItems="center"
      >
        <Stack>
          <Title>{name}</Title>
          {Boolean(listingsCount) && (
            <TextValue>
              {listingsCount === 1
                ? t('singleListingCount', {
                    count: listingsCount,
                  })
                : t('listingsCount', {
                    count: listingsCount ?? 0,
                  })}
            </TextValue>
          )}
        </Stack>
        {isEnrichDataLoading ? (
          <SkeletonText width="10px" />
        ) : (
          problemStatement && (
            <LightTooltip
              content={
                <Stack gap="3px">
                  <TooltipTitle>{t`problemStatement`}</TooltipTitle>
                  {problemStatement}
                </Stack>
              }
              withArrow
              padding="14px 20px"
            >
              <Icon icon="ExclamationMark" />
            </LightTooltip>
          )
        )}
      </Flexbox>
    </SuggestionWrapper>
  );
};

const SuggestionWrapper = styled(Flexbox)`
  position: relative;
  width: 250px;
  padding: 12px 20px;
`;

const Title = styled('h5')`
  font-weight: 600;
  font-size: 14px;
  color: ${colors.basics.black};
`;
