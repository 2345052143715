import type { APIResponse } from './base-fetch';
import { post, type APIRequestBody } from './base-fetch';

export type CopyProjectPayload =
  APIRequestBody<'/api/v4/projects/copy-to-account'>;

export type CopyProjectResponse =
  APIResponse<'/api/v4/projects/copy-to-account'>;

export const copyProjectsToAccount = (payload: CopyProjectPayload) =>
  post<CopyProjectResponse>('/v4/projects/copy-to-account', payload);
