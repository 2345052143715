import { colors } from '@/theme/colors';
import type { HeaderCellConfig } from '@/ui/table/table.types';

export const projectSuggestionsTableHeaders: HeaderCellConfig[] = [
  {
    columnKey: 'projects',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'tags',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'stage',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'createdFor',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'status',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'lastModified',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
  {
    columnKey: 'createdBy',
    bgColor: colors.basics.white,
    withSort: false,
    padding: '20px 12px',
  },
];
