import { useEffect } from 'react';
//import { useLocation } from 'react-router-dom';
//import type { IntercomProps } from 'react-use-intercom';
//import { useIntercom } from 'react-use-intercom';
import * as Sentry from '@sentry/react';
import { useCurrentUser } from './user/use-current-user.query';

export const useSosaAppConfig = () => {
  //const { update } = useIntercom();
  //const location = useLocation();
  const { data: currentUser } = useCurrentUser();
  // const [isIntercomInitialized, setIsIntercomInitialized] = useState(false);

  useEffect(() => {
    Sentry.setUser({
      id: currentUser?.id,
      email: currentUser?.email,
    });
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      // && !isIntercomInitialized) {
      const account = currentUser.account;

      // const initIntercom = () => {
      //   const { VITE_INTERCOM_APP_ID } = window.runTimeEnvs;
      //   if (VITE_INTERCOM_APP_ID) {
      //     const intercomVariables: IntercomProps = {
      //       userId: currentUser.id.toString(),
      //       email: currentUser.email,
      //       name: `${currentUser.firstName} ${currentUser.lastName}`,
      //       customAttributes: {
      //         account: account ? account.id : '',
      //       },
      //       userHash: currentUser.intercomHash,
      //       company: {
      //         companyId: account ? account.id : '',
      //         name: account ? account.name : '',
      //       },
      //     };
      //     update(intercomVariables);
      //   }
      // };

      const initPendo = () => {
        if (window.pendo) {
          window.pendo.initialize({
            visitor: {
              id: currentUser.id.toString(),
              email: currentUser.email,
              full_name: `${currentUser.firstName} ${currentUser.lastName}`,
              role: currentUser.role,
              teamId: currentUser.team ? currentUser.team.id : null,
              teamName: currentUser.team ? currentUser.team.name : null,
            },
            account: {
              id: account ? account.id : '',
              name: account ? account.name : '',
            },
          });
        }
      };
      if (account) {
        //initIntercom();
        initPendo();
        //setIsIntercomInitialized(true);
      }
    }
  }, [currentUser]);

  // useEffect(() => {
  //   const { VITE_INTERCOM_APP_ID } = window.runTimeEnvs;
  //   if (VITE_INTERCOM_APP_ID) {
  //     update();
  //   }
  // }, [location.pathname, update]);
};
