import { Flexbox } from '@/ui/flexbox/flexbox';
import type { CompanySearchSuggestion } from './company-suggestions-table';
import { Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { ParagraphSmall } from '@/ui/typography/widgets';
import { LinkIcon } from '@/ui/link/link-icon';
import { styled } from 'goober';
import { useTranslation } from 'react-i18next';
import { colors } from '@/theme/colors';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { formatLocation } from '@/helpers/format-location';
import {
  LightTooltip,
  SuggestionWrapper,
  TextValue,
  TooltipTitle,
} from '../enriched-table/ui';

type CompanyRowMainCellProps = Pick<
  CompanySearchSuggestion['enrichedData'],
  'keyOffering' | 'location' | 'website' | 'linkedInUrl'
> &
  Pick<CompanySearchSuggestion['basicData'], 'name' | 'logoUrl'> & {
    isEnrichDataLoading: boolean;
  };

export const CompanyRowMainCell = ({
  name,
  logoUrl,
  keyOffering,
  location,
  website,
  linkedInUrl,
  isEnrichDataLoading,
}: CompanyRowMainCellProps) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'globalSearch.suggestionsTable',
  });

  return (
    <SuggestionWrapper
      name="company-suggestion-main-cell"
      justify="space-between"
      alignContent="center"
      alignItems="center"
    >
      <Flexbox name="company-suggestion-data">
        <Logo
          border="none"
          name={name}
          logoUrl={logoUrl}
          size="32px"
          singleLetter
          fontSize="14px"
        />
        <Stack gap="0" margin="0 0 0 12px">
          <LightTooltip
            content={
              <Stack gap="3px">
                <TooltipTitle>{t`keyOffering`}</TooltipTitle>
                {keyOffering}
              </Stack>
            }
            visible={!!keyOffering}
            withArrow
            padding="14px 20px"
          >
            <ParagraphSmall>{name}</ParagraphSmall>
          </LightTooltip>
          {isEnrichDataLoading ? (
            <SkeletonText width="80%" />
          ) : (
            <TextValue>
              {location
                ? formatLocation(
                    {
                      cityName: location.city?.name,
                      countryName: location.country?.name,
                      regionName: location.region?.name,
                    },
                    {
                      compact: true,
                    },
                  )
                : ''}
            </TextValue>
          )}
        </Stack>
      </Flexbox>

      {isEnrichDataLoading ? (
        <SkeletonText width="20px" />
      ) : (
        <Flexbox name="search-result-urls" alignItems="center">
          {website && (
            <StyledLinkIcon
              url={website}
              icon="Globe"
              size="16px"
              stopPropagation
              needRightSpacing={!linkedInUrl}
            />
          )}
          {linkedInUrl && (
            <StyledLinkIcon
              icon="LinkedIn"
              url={linkedInUrl}
              size="16px"
              color={colors.blue.primaryA}
              stopPropagation
            />
          )}
        </Flexbox>
      )}
    </SuggestionWrapper>
  );
};

const StyledLinkIcon = styled(LinkIcon)<{
  needRightSpacing?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-left: 5px;
  ${({ needRightSpacing }) => needRightSpacing && 'margin-right: 21.5px;'}
`;
