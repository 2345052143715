import { useSidebars } from '@/hooks/use-sidebars';
import { useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useProjectSidebar = () => {
  const { isProjectSidebarOpen, toggleProjectSidebar } = useSidebars();
  const [searchParams, setSearchParams] = useSearchParams();
  const isSidebarFlagParamAdded = searchParams.has('openSidebar');

  const updateSearchParams = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        searchParams.set('openSidebar', 'true');
      } else {
        searchParams.delete('openSidebar');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleToggleProjectSidebar = useCallback(() => {
    const newSidebarState = !isProjectSidebarOpen;
    updateSearchParams(newSidebarState);
    toggleProjectSidebar();
  }, [isProjectSidebarOpen, toggleProjectSidebar, updateSearchParams]);

  useEffect(() => {
    if (isSidebarFlagParamAdded !== isProjectSidebarOpen) {
      toggleProjectSidebar();
    }
  }, [isProjectSidebarOpen, isSidebarFlagParamAdded, toggleProjectSidebar]);

  return {
    isOpen: isProjectSidebarOpen,
    handleChangeToggleProjectSidebar: handleToggleProjectSidebar,
  };
};
