import { fetchEnrichedProjects } from '@/api/v4/project-enrich.api';
import { QueryKey } from '@/config/query-client';
import { getTimeInMs } from '@/helpers/get-time-in-ms';
import { useQuery } from '@tanstack/react-query';

export const useEnrichedProjectSuggestions = (projectIds: string[]) => {
  const getEnrichedProjects = async () => {
    if (!projectIds.length) {
      return [];
    }

    return await fetchEnrichedProjects({
      projectIds,
    });
  };

  return useQuery(
    [QueryKey.ProjectEnrichedSearchSuggestions, projectIds],
    getEnrichedProjects,
    {
      staleTime: getTimeInMs(5, 'minute'),
      cacheTime: getTimeInMs(5, 'minute'),
    },
  );
};
