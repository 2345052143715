import { Modal } from '@/ui/modal/modal';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { modalState } from '@/state/modal.state';
import { CopyToAccount } from '../projects/copy-to-account/copy-to-account';

export const CopyProjectModal = () => {
  const { t } = useTranslation('projects');
  const [modal, setModalState] = useRecoilState(modalState);

  if (modal?.state !== 'copyProjectModal') return null;

  const close = () => {
    setModalState(null);
  };

  return (
    <Modal
      data-testid="copy-project-modal"
      header={t`copyToAccount.modalHeader`}
      onClose={close}
      variant="info"
    >
      <CopyToAccount projectId={modal.projectId} onClose={close} />
    </Modal>
  );
};
