import { styled } from 'goober';

import { prepareLink } from '@/utils/link';

import { Icon, type IconVariant } from '../icons/icon';
import { Tooltip } from '../tooltip/tooltip';

export interface LinkIconProps {
  url: string;
  icon: IconVariant;
  size?: string;
  disableTooltip?: boolean;
  dataTestId?: string;
  color?: string;
  stopPropagation?: boolean;
  className?: string;
}

export const LinkIcon = ({
  url,
  icon,
  size,
  disableTooltip,
  dataTestId,
  color,
  stopPropagation,
  className,
}: LinkIconProps) => (
  <Link
    href={prepareLink(url)}
    target="_blank"
    size={size}
    data-testid={dataTestId}
    onClick={e => {
      if (stopPropagation) {
        e.stopPropagation();
      }
    }}
    className={className}
  >
    <Tooltip disabled={disableTooltip} content={url} maxWidth="unset">
      <Icon icon={icon} color={color} />
    </Tooltip>
  </Link>
);

const Link = styled('a')<{ size?: string }>`
  width: ${({ size }) => size ?? '18px'};
  height: ${({ size }) => size ?? '18px'};

  svg {
    width: 100%;
    height: 100%;
  }
`;
