import React, { useState } from 'react';
import { Stack } from '@/ui/line/line';
import { styled } from 'goober';

type IdentifiableItem = {
  id: string;
};

export type ListProps<T = unknown> = {
  items: T[];
  bordered?: boolean;
  multiple?: boolean;
  maxHeight?: string;
  onSelectItem?: (item: T[]) => void;
  renderItem: (item: T) => React.ReactElement;
};

export const List = <T extends IdentifiableItem>(props: ListProps<T>) => {
  const [selected, setSelected] = useState<T[]>([]);

  const onSelectItem = (item: T) => {
    setSelected((previousValue: T[]) => {
      const isSelected = previousValue.some(
        selectedItem => selectedItem.id === item.id,
      );

      if (isSelected) {
        const filteredList = previousValue.filter(
          selectedItem => selectedItem.id !== item.id,
        );
        props.onSelectItem?.(filteredList);
        return filteredList;
      }

      if (!props.multiple) {
        props.onSelectItem?.([item]);
        return [item];
      }

      const latestSelection = [...previousValue, item];
      props.onSelectItem?.([...previousValue, item]);
      return latestSelection;
    });
  };

  return (
    <ListParent maxHeight={props.maxHeight}>
      {props.items.map((item, index) => {
        const isSelected = selected.some(
          selectedItem => selectedItem.id === item.id,
        );
        return (
          <ListItem
            key={`list-item-${index}`}
            bordered={props.bordered}
            onClick={() => onSelectItem(item)}
            selected={isSelected}
          >
            {props.renderItem(item)}
          </ListItem>
        );
      })}
    </ListParent>
  );
};

type ListItemProps = Pick<ListProps, 'bordered'> & {
  selected?: boolean;
};

const ListParent = styled(Stack)<{ maxHeight?: ListProps['maxHeight'] }>`
  max-height: ${({ maxHeight }) => maxHeight ?? 'auto'};
  overflow-y: auto;
`;

const ListItem = styled('div')<ListItemProps>`
  border-bottom: ${({ theme, bordered }) =>
    bordered ? `1px solid ${theme.colors.gray.c2}` : 'none'};
  background: ${({ theme, selected }) =>
    selected ? theme.colors.blue.c8 : 'none'};
  padding: 16px 6px;
  width: 100%;
  cursor: default;
  transition: background 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.blue.c8};
  }
`;
