import { AuthProvider } from '@descope/react-sdk';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
//import { IntercomProvider } from 'react-use-intercom';
import { RecoilRoot } from 'recoil';

import { queryClient } from '@/config/query-client';

import 'react-dates/initialize';

import RecoilNexus from './shared/recoil-nexus';

import 'react-dates/lib/css/_datepicker.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './one-password.css';
import { AppRouter } from './routes/app-router';
import { PageNotFound } from './routes/page-not-found';

if (window.runTimeEnvs.VITE_SENTRY_DSN) {
  const networkDetailAllowUrls = window.runTimeEnvs.VITE_API_BASEURL
    ? [window.runTimeEnvs.VITE_API_BASEURL]
    : [];

  if (!window.runTimeEnvs.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE) {
    throw new Error(
      'Sentry replay error sample rate not set: ' +
        window.runTimeEnvs.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE,
    );
  }
  const replaysOnErrorSampleRate = Number(
    window.runTimeEnvs.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE,
  );

  if (!window.runTimeEnvs.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE) {
    throw new Error(
      'Sentry replay session sample rate not set: ' +
        window.runTimeEnvs.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE,
    );
  }
  const replaysSessionSampleRate = Number(
    window.runTimeEnvs.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  );

  if (!window.runTimeEnvs.VITE_SENTRY_TRACE_SESSION_SAMPLE_RATE) {
    throw new Error(
      'Sentry trace session sample rate not set: ' +
        window.runTimeEnvs.VITE_SENTRY_TRACE_SESSION_SAMPLE_RATE,
    );
  }
  const traceSessionSampleRate = Number(
    window.runTimeEnvs.VITE_SENTRY_TRACE_SESSION_SAMPLE_RATE,
  );

  if (!window.runTimeEnvs.VITE_SENTRY_PROFILE_SESSION_SAMPLE_RATE) {
    throw new Error(
      'Sentry profile session sample rate not set: ' +
        window.runTimeEnvs.VITE_SENTRY_PROFILE_SESSION_SAMPLE_RATE,
    );
  }
  const profileSessionSampleRate = Number(
    window.runTimeEnvs.VITE_SENTRY_PROFILE_SESSION_SAMPLE_RATE,
  );

  if (!window.runTimeEnvs.VITE_SENTRY_ENVIRONMENT) {
    throw new Error(
      'Sentry environment not set: ' +
        window.runTimeEnvs.VITE_SENTRY_ENVIRONMENT,
    );
  }
  const sentryEnvironment = window.runTimeEnvs.VITE_SENTRY_ENVIRONMENT;

  Sentry.init({
    dsn: window.runTimeEnvs.VITE_SENTRY_DSN,
    attachStacktrace: true,
    autoSessionTracking: true,
    environment: sentryEnvironment,
    debug: false,
    //release: true,
    //debug: true,
    //release: "my-project-name@" + window.buildTimeEnvs.npm_package_version, //TODO: @Adam - pass it from CI

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: replaysSessionSampleRate, // ToDo: ENV, pro

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: replaysOnErrorSampleRate,
    enableTracing: true,
    tracesSampleRate: traceSessionSampleRate,
    profilesSampleRate: profileSessionSampleRate,
    tracePropagationTargets: ['localhost', window.runTimeEnvs.VITE_API_V4URL],
    ignoreErrors: [/Non-Error promise rejection captured with value: Timeout/],
    integrations: [
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
        networkDetailAllowUrls: networkDetailAllowUrls,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      Sentry.extraErrorDataIntegration(),
    ],
  });
}

function App() {
  return (
    <ErrorBoundary FallbackComponent={PageNotFound}>
      {/* <IntercomProvider
        appId={window.runTimeEnvs.VITE_INTERCOM_APP_ID ?? ''}
        autoBoot={window.runTimeEnvs.VITE_INTERCOM_APP_ID ? true : false}
      > */}
      <AuthProvider
        projectId={window.runTimeEnvs.VITE_DESCOPE_PROJECT_ID}
        baseUrl={window.runTimeEnvs.VITE_DESCOPE_COOKIE_DOMAIN}
      >
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <RecoilNexus />
            <AppRouter />
          </RecoilRoot>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </AuthProvider>
      {/* </IntercomProvider> */}
    </ErrorBoundary>
  );
}

export default App;
