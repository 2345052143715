import type { SearchSuggestion } from '@/api/v4/search.api';
import type { ProjectSearchSuggestion } from './project-suggestions-table';
import type { EnrichedProjectSearchSuggestion } from '@/api/v4/project-enrich.api';

const DEFAULT_ENRICHED_PROJECT_SUGGESTION: EnrichedProjectSearchSuggestion = {
  id: '',
  tags: [],
  status: null,
  mostAdvancedListingStatus: null,
  problemStatement: null,
  createdForTeam: { id: '', name: '' },
  creator: { id: 0, firstName: '', lastName: '' },
  lastEditedDate: null,
};

export const combineProjectData = (
  data: SearchSuggestion[],
  enrichedProjectSuggestions: EnrichedProjectSearchSuggestion[] | undefined,
): ProjectSearchSuggestion[] => {
  return data.map(suggestion => {
    const enrichedProjectSuggestion =
      enrichedProjectSuggestions?.find(
        enriched => enriched.id === suggestion.id,
      ) ?? DEFAULT_ENRICHED_PROJECT_SUGGESTION;

    return {
      basicData: { ...suggestion },
      enrichedData: { ...enrichedProjectSuggestion },
    };
  });
};
