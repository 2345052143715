import type { APIRequestBody } from './base-fetch';
import { post, type APIResponse } from './base-fetch';
import type { Tag } from './organization-enrich.api';

type ProjectCreator = {
  id: number;
  firstName: string;
  lastName: string;
};

export type ProjectStatus = 'active' | 'on_hold' | 'stuck' | 'done';

export type EnrichedProjectSearchSuggestion = Omit<
  APIResponse<'/api/v4/projects/enrich'>[number],
  'tags' | 'creator' | 'status'
> & {
  tags: Tag[];
  creator: ProjectCreator;
  status: ProjectStatus | null;
};

export type GetEnrichedProjectsPayload =
  APIRequestBody<'/api/v4/projects/enrich'>;

export const fetchEnrichedProjects = async (
  payload: GetEnrichedProjectsPayload,
) => post<EnrichedProjectSearchSuggestion[]>('v4/projects/enrich', payload);
