import { fonts } from '@/theme/typography';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { SmallText } from '@/ui/typography/widgets';
import { styled } from 'goober';

export const TextCell = ({
  value,
  isLoading,
  maxWidth,
}: {
  value: string | number | null;
  isLoading: boolean;
  maxWidth?: string;
}) => {
  return isLoading ? (
    <SkeletonText />
  ) : (
    <TextValue maxWidth={maxWidth}>{value ?? ''}</TextValue>
  );
};

const TextValue = styled(SmallText)<{ maxWidth?: string }>`
  ${({ theme }) => theme.typography.enhance.tableSmallText};
  font-family: ${fonts.wallop};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};
  ${({ theme }) => theme.mixins.ellipsis}
`;
