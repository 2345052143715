import { parseSearchParams } from '@/routes/helpers/parse-search-params';
import { Link } from '@/ui/link/link';
import { styled } from 'goober';
import { TagWithEllipsis } from './tag-with-ellipsis';
import type { TagSize } from '@/ui/tag/general-tag';

export const TagLink = ({
  id,
  name,
  baseUrl,
  size = 's',
}: {
  id: string;
  name: string;
  baseUrl: string;
  size?: TagSize;
}) => {
  return (
    <TagAsLink
      to={
        baseUrl +
        parseSearchParams({
          tags: id,
        })
      }
    >
      <TagWithEllipsis
        smallScreenMaxWidth="80px"
        maxWidth="120px"
        text={name}
        variant="blue"
        size={size}
      />
    </TagAsLink>
  );
};

const TagAsLink = styled(Link)`
  margin-right: 8px;
  margin-bottom: 5px;
`;
