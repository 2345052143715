import type { ProjectStatus } from '@/api/v4/project-enrich.api';
import i18n from '@/lib/i18n/i18n';
import { colors } from '@/theme/colors';
import { Inline } from '@/ui/line/line';
import { SmallText } from '@/ui/typography/widgets';
import { styled } from 'goober';

const statusColorConfig = {
  active: colors.system.active,
  on_hold: colors.system.mediumFit,
  stuck: colors.system.disable,
  done: colors.system.highFit,
};

const statusTextConfig = {
  active: i18n.t('projects:projectStatus.active'),
  on_hold: i18n.t('projects:projectStatus.onHold'),
  stuck: i18n.t('projects:projectStatus.stuck'),
  done: i18n.t('projects:projectStatus.completed'),
};

export const StatusIndicator = ({ status }: { status: ProjectStatus }) => {
  return (
    <Inline gap="12px" alignItems="center">
      <Circle colorValue={statusColorConfig[status]} />
      <SmallText color={colors.basics.black}>
        {statusTextConfig[status]}
      </SmallText>
    </Inline>
  );
};

const Circle = styled('div')<{ colorValue: string }>`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${({ colorValue }) => colorValue};
`;
