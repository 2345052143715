import { styled } from 'goober';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { Modal as ModalComponent } from '@/ui/modal/modal';

import { Search } from '../search/global-search/search';
import { searchResultsFilterState } from '../search/global-search/search.state';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@/config/query-client';

const SEARCH_MODAL_WIDTH = 688;
const LARGE_SEARCH_MODAL_WIDTH = 1600;

export const SearchModal = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const appliedFilter = useRecoilValue(searchResultsFilterState);
  const resetFilters = useResetRecoilState(searchResultsFilterState);
  const queryClient = useQueryClient();

  const isContentWithLargeTable = ['company', 'project'].includes(
    appliedFilter,
  );

  if (modal?.state !== 'search') {
    return null;
  }

  const onCloseModal = () => {
    resetFilters();
    queryClient.removeQueries([QueryKey.CompaniesSearchSuggestions]);
    queryClient.removeQueries([QueryKey.ProjectEnrichedSearchSuggestions]);
    setModal(null);
  };

  return (
    <Modal
      closeIcon={false}
      onClose={onCloseModal}
      size={isContentWithLargeTable ? 'large' : 'regular'}
    >
      <Search />
    </Modal>
  );
};

const Modal = styled(ModalComponent)<{ size: 'regular' | 'large' }>`
  min-height: 400px;
  max-height: 900px;
  flex-direction: row;
  max-width: ${({ size }) =>
    size === 'large' ? LARGE_SEARCH_MODAL_WIDTH : SEARCH_MODAL_WIDTH}px;
`;
