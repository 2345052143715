import { keyframes } from 'goober';

const slideOutHorizontallyTransition = (
  width: string,
  slideFrom: 'right' | 'left',
) => {
  const slide = keyframes`
    0% {
      transform: translate3d(0, 0,0);
    }
    100% {
      transform: translate3d(${
        (slideFrom === 'left' ? '-' : '') + width
      }, 0, 0);
    }
  `;
  return `
    animation: ${slide} 0.1s ease-out 0s forwards;
  `;
};

const slideInHorizontallyTransition = (
  slideFrom: 'right' | 'left',
  width = '400px',
  time = '0.2s',
) => {
  const slide = keyframes`
    0% {
      transform: translate3d(${slideFrom === 'left' ? '-' : ''}${width}, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  `;
  return `
    animation: ${slide} ${time} ease-out 0.1s backwards;
  `;
};

const fadeAnimation = () => {
  const fadeIn = keyframes`
    0% {  
      opacity: 0; 
    }
    100% { 
      opacity: 1; 
    }
  `;

  return `animation: ${fadeIn} 0.8s`;
};

const slideInVerticallyTransition = (
  slideFrom: 'top' | 'bottom',
  offsetTop?: string,
) => {
  const offset = offsetTop
    ? `${slideFrom === 'top' ? '-' : ''}${offsetTop}`
    : '0';
  const slide = keyframes`
    0% {
      transform: translate3d(0, ${offset}, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  `;
  return `
    animation: ${slide} 0.3s ease-out 0.1s backwards;
  `;
};

const skeletonAnimation = () => {
  const skeletonLoading = keyframes`
    0% {
      background-color: hsl(200, 20%, 80%);
    }

    100% {
      background-color: hsl(200, 20%, 90%);
    }
  `;

  return `
    animation: ${skeletonLoading} 1s linear infinite alternate;
  `;
};

const blinkAnimation = ({
  numberOfElements,
  lightColor,
  darkColor,
}: {
  numberOfElements: number;
  lightColor: string;
  darkColor: string;
}) => {
  const blink = keyframes`
   0% {
      background-color: ${darkColor};
    }
    20% {
      background-color: ${lightColor};
      transform: scale(1.2);
    }
    100% {
      background-color: ${darkColor};
    }
  `;

  const generateAnimationDelays = (numberOfElements: number) => {
    let css = '';

    for (let i = 2; i <= numberOfElements + 1; i++) {
      css += `&:nth-child(${i}) {
      animation-delay: ${(i - 2) * 0.2}s;
    }\n`;
    }

    return css;
  };

  return `
    animation: ${blink} 1s infinite both;
    ${generateAnimationDelays(numberOfElements)}
  `;
};

export const animations = {
  slideOutHorizontally: slideOutHorizontallyTransition,
  slideInHorizontally: slideInHorizontallyTransition,
  slideInVertically: slideInVerticallyTransition,
  skeletonAnimation,
  fadeAnimation,
  blinkAnimation,
};
