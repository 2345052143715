import { AmountIndicator } from '@/ui/amount-indicator/amount-indicator';
import { RelatedProjects } from './related-projects';
import { PopperComponent } from '@/components/popper/popper-component';

interface RelatedProjectsCountProps {
  value: number;
  companyId: string;
}

export const RelatedProjectsCount = ({
  value,
  companyId,
}: RelatedProjectsCountProps) => {
  if (!value) {
    return null;
  }

  return (
    <PopperComponent
      visibleElement={<AmountIndicator amount={value} />}
      PopperContent={<RelatedProjects companyId={companyId} />}
      dataId={companyId}
      dataName="related-projects-amount-indicator"
    />
  );
};
