import { selectedCompaniesState } from '@/features/companies/companies.state';
import { modalState } from '@/state/modal.state';
import { IconButton } from '@/ui/button/icon-button';
import { Inline } from '@/ui/line/line';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

export const CompanyActions = ({
  companyId,
  companyName,
}: {
  companyId: string;
  companyName: string;
}) => {
  const { t } = useTranslation('companies');
  const setModalState = useSetRecoilState(modalState);
  const { hasRequiredPermission } = useUserPermissions();
  const setSelectedCompanies = useSetRecoilState(selectedCompaniesState);

  return (
    <Inline alignItems="center" justifyContent="center">
      {hasRequiredPermission(PERMISSION.ADD_COMPANIES_TO_PROJECTS) && (
        <Tooltip content={t`companies.companyActions.addToProject`}>
          <IconButton
            variant="primary"
            icon="Plus"
            onClick={e => {
              e.stopPropagation();
              setSelectedCompanies([{ organizationId: companyId }]);
              setModalState({ state: 'addCompanyToProjects', companyName });
            }}
          />
        </Tooltip>
      )}
    </Inline>
  );
};
